import React from 'react';
import PageTitle from 'src/components/PageTitle';
import withAuth from 'src/lib/with-auth';
import HomeModule from 'src/modules/home/views';
import { gotoNext } from 'src/lib/goto-next';
import { getStorage } from 'src/lib/storage';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'home',
        'left_navigation',
        'profile',
      ])),
    },
  };
}

const Home: FunctionPage = () => {
  React.useLayoutEffect(() => {
    const storage = getStorage();

    const redirectObj = storage.get('redirect_on_load');
    storage.clear('redirect_on_load');

    // Check if the subdomain are same before redirecting
    // or if there is no subdomain if you are returning to url visited before login
    if (
      redirectObj?.subdomain !== window._po_subdomain &&
      redirectObj?.subdomain
    )
      return;

    if (redirectObj) {
      gotoNext(redirectObj.nextURL);
    }
  }, []);

  return (
    <>
      <PageTitle>Home</PageTitle>
      <HomeModule />
    </>
  );
};

export default withAuth(Home);
