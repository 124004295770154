import React from 'react';
import Head from 'next/head';

const PageTitle = ({ children }) => (
  <Head>
    <title>{children} | ReachBee Dashboard</title>
  </Head>
);

export default PageTitle;
